ymaps.ready(init);

function init () {
    var myMap = new ymaps.Map('map', {
            center: [55.702551, 37.562355],
            zoom: 16
        });
 myPlacemark = new ymaps.Placemark([55.702551, 37.566701], {
            // Чтобы балун и хинт открывались на метке, необходимо задать ей определенные свойства.
           
            hintContent: "г. Москва, Ленинский проспект 42/1"
        });

    myMap.geoObjects.add(myPlacemark);

    
    // Для добавления элемента управления на карту
    // используется поле map.controls.
    // Это поле содержит ссылку на экземпляр класса map.control.Manager.
    
    // Добавление элемента в коллекцию производится
    // с помощью метода add.

    // В метод add можно передать строковый идентификатор
    // элемента управления и его параметры.
    myMap.controls
        // Кнопка изменения масштаба.
        .add('zoomControl', { right: 10, top: 60 })
        // Список типов карты
        .add('typeSelector')
        // Стандартный набор кнопок
        .add('mapTools', { right: 240, top: 5 });

    // Также в метод add можно передать экземпляр класса элемента управления.
    // Например, панель управления пробками.
    var trafficControl = new ymaps.control.TrafficControl();
    myMap.controls
        .add(trafficControl)
        // В конструкторе элемента управления можно задавать расширенные
        // параметры, например, тип карты в обзорной карте.
        .add(new ymaps.control.MiniMap({
            type: 'yandex#publicMap'
        }));
    
    /*
    // Удаление элементов управления производится через метод remove.
    myMap.controls
        .remove(trafficControl)
        .remove('mapTools');
    */
}